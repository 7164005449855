import type { RumInitConfiguration } from '@datadog/browser-rum'
import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: 'datadog-rum',
  parallel: true,
  async setup() {
    const runtimeConfig = useRuntimeConfig()
    const initRumConfig = runtimeConfig.public.datadog.rum as RumInitConfiguration
    const { datadogRum } = await import('@datadog/browser-rum')

    if (!process.dev) {
      datadogRum.init(initRumConfig)

      datadogRum.startSessionReplayRecording()

      const ctx = datadogRum.getInternalContext()
      const applicationId = ctx?.application_id ?? 'NULL'
      const sessionId = ctx?.session_id ?? 'NULL'

      // eslint-disable-next-line no-console
      console.debug(`Datadog RUM initialized with applicationId: ${applicationId} and sessionId: ${sessionId}`)
    }

    return {
      provide: {
        datadogRum,
      },
    }
  },
})
