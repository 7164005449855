import type { LoggerOptions } from 'pino'

const loggerOptions: LoggerOptions = {}

const pinoPretty = {
  target: 'pino-pretty',
  options: {
    colorize: true,
    translateTime: 'SYS:HH:MM:ss.l o',
    ignore: 'pid,hostname',
    levelFirst: true,
  },
}

export default defineNuxtPlugin({
  name: 'pino',
  parallel: true,
  async setup() {
    const pinoConfig = useRuntimeConfig().public.pino
    Object.assign(loggerOptions, pinoConfig)

    if (process.server) {
      loggerOptions.name = 'nuxt.server'

      if (pinoConfig?.pretty)
        loggerOptions.transport = pinoPretty
    }

    if (process.client)
      loggerOptions.name = 'nuxt.client'

    const { pino } = await import('pino')

    return {
      provide: {
        pino: pino(loggerOptions),
      },
    }
  },
})
