import revive_payload_client_8nURURVieF from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_DfH6BByFqA from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FYcq6rSRPs from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PbCw8UWmDV from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_b1IFUU8C72 from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/usr/local/app/.nuxt/components.plugin.mjs";
import prefetch_client_kdps7Wesph from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import provideState_HxRhSV3vqQ from "/usr/local/app/node_modules/.pnpm/nuxt-graphql-middleware@4.0.0-beta.9_graphql-tag@2.12.6_graphql@16.8.1_nuxt@3.9.1_rollup@3.29_yfqp253n2wihqfpsh4kfefo2fq/node_modules/nuxt-graphql-middleware/dist/runtime/plugins/provideState.mjs";
import plugin_aEL9vYN3fL from "/usr/local/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_rollup@3.29.4/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_client_aZBwiZAQTj from "/usr/local/app/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/usr/local/app/.nuxt/unocss.mjs";
import i18n_s83hHPopOx from "/usr/local/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.1_rollup@3.29.4_vue@3.4.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import nuxt_plugin_da0l7bk1eK from "/usr/local/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@3.29.4/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import pwa_client_hRlQYXti7Y from "/usr/local/app/node_modules/.pnpm/@vite-pwa+nuxt@0.4.0_rollup@3.29.4_vite@5.0.11_workbox-build@7.0.0_workbox-window@7.0.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_TFJPuNgw6e from "/usr/local/app/node_modules/.pnpm/@nuxt+image@1.2.0_rollup@3.29.4/node_modules/@nuxt/image/dist/runtime/plugin.mjs";
import plugin_HMUycbzStU from "/usr/local/app/modules/pino/runtime/plugin.ts";
import plugin_xDtleEcACi from "/usr/local/app/modules/gtm/runtime/plugin.ts";
import plugin_client_n65crEo4Aa from "/usr/local/app/modules/datadog/runtime/plugin.client.ts";
import chunk_reload_client_igrZr2HRHU from "/usr/local/app/node_modules/.pnpm/nuxt@3.9.1_eslint@8.52.0_rollup@3.29.4_typescript@5.2.2_vite@5.0.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _0_graphqlConfig_yaUZblG674 from "/usr/local/app/plugins/0.graphqlConfig.ts";
import _1_auth_slaK5pfDbE from "/usr/local/app/plugins/1.auth.ts";
import _2_activeOrder_yYINElYs5H from "/usr/local/app/plugins/2.activeOrder.ts";
import foxentry_client_gcy3vwc779 from "/usr/local/app/plugins/foxentry.client.ts";
import scroll_client_HJUo2UKx9n from "/usr/local/app/plugins/scroll.client.ts";
import sentry_client_shVUlIjFLk from "/usr/local/app/plugins/sentry.client.ts";
import vee_validate_KcKlKmvCrJ from "/usr/local/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_8nURURVieF,
  unhead_DfH6BByFqA,
  router_FYcq6rSRPs,
  payload_client_PbCw8UWmDV,
  check_outdated_build_client_b1IFUU8C72,
  components_plugin_KR1HBZs4kY,
  prefetch_client_kdps7Wesph,
  provideState_HxRhSV3vqQ,
  plugin_aEL9vYN3fL,
  plugin_client_aZBwiZAQTj,
  unocss_MzCDxu9LMj,
  i18n_s83hHPopOx,
  nuxt_plugin_da0l7bk1eK,
  pwa_client_hRlQYXti7Y,
  plugin_TFJPuNgw6e,
  plugin_HMUycbzStU,
  plugin_xDtleEcACi,
  plugin_client_n65crEo4Aa,
  chunk_reload_client_igrZr2HRHU,
  _0_graphqlConfig_yaUZblG674,
  _1_auth_slaK5pfDbE,
  _2_activeOrder_yYINElYs5H,
  foxentry_client_gcy3vwc779,
  scroll_client_HJUo2UKx9n,
  sentry_client_shVUlIjFLk,
  vee_validate_KcKlKmvCrJ
]