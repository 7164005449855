export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const router = useRouter()
    const runtimeConfig = useRuntimeConfig()

    const Sentry = await import('@sentry/vue')

    if (!runtimeConfig.public.sentry.dsn)
      return

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: runtimeConfig.public.sentry.dsn,
      environment: runtimeConfig.public.sentry.environment,
      release: runtimeConfig.public.sentry.release,
      trackComponents: true,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
      ],

      tracesSampleRate: runtimeConfig.public.sentry.tracesSampleRate,
      tracePropagationTargets: ['localhost', 'https://uzo.com', 'https://uzo.io'],
      replaysSessionSampleRate: runtimeConfig.public.sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: runtimeConfig.public.sentry.replaysOnErrorSampleRate,
    })
  },
})
